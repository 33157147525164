
  import { defineComponent } from 'vue';
  import { userOrigin } from '@/utils/constants';
  import Button from '@/components/Button.vue'
  import { mapGetters } from 'vuex';
  export default defineComponent({
    name : 'UsersOriginSelector',
    components:{
        Button
    },
    props:{
        origin: {
            type: String
        },
        type: {
            type: String
        }
    },
    data(){
        return{
            userOrigin: userOrigin
        }
    },
    computed: {
        ...mapGetters([
            'productsEnabled',
        ]),
    },
    methods: {
        showPanel() {
          const TYPE_PRODUCTS_RELATION: Record<string, string[]> = {
            WAT_UX_QUANTI: ["WAT_UX_QUANTI_PANEL"],
            WAT_UX: ["WAT_UX_THINKING_ALOUD_PANEL"],
            CLICK_TEST: ["WAT_UX_CLICK_TEST_PANEL"],
            WAT_FOCUS: ["WAT_FOCUS_PANEL"],
            WAT_FOCUS_INDEPENDENT: ["WAT_FOCUS_PANEL"],
          };

          return (
            this.type &&
            TYPE_PRODUCTS_RELATION[this.type]?.some((product: string) =>
              this.productsEnabled.includes(product)
            )
          );
        },
        showNoPanel() {
          const TYPE_PRODUCTS_RELATION: Record<string, string[]> = {
            WAT_UX_QUANTI: ["WAT_UX_QUANTI_NOPANEL"],
            WAT_UX: ["WAT_UX_THINKING_ALOUD_NOPANEL"],
            CLICK_TEST: ["WAT_UX_CLICK_TEST_NOPANEL"],
            WAT_FOCUS: ["WAT_FOCUS_NOPANEL"],
            WAT_FOCUS_INDEPENDENT: ["WAT_FOCUS_NOPANEL"],
          };

          return (
            this.type &&
            TYPE_PRODUCTS_RELATION[this.type]?.some((product: string) =>
              this.productsEnabled.includes(product)
            )
          );
        }
    }
  })
  